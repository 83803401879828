<template>
  <div>
    <div class="contact">
      <img src="../assets/img/contact_bg.svg" alt="" class="contact_bg" />
      <div class="floor1">
        <div class="title1">Contact us</div>
        <div class="title2">Contact us</div>
        <img src="../assets/img/map-location.svg" alt="" class="floor1_img" />
      </div>
      <contact></contact>
    </div>
    <div class="h5">
      <img src="../assets/img/h5/Group_159.png" alt="" />
      <contact></contact>
    </div>
  </div>
</template>
<script>
import Contact from "@/components/Contact";
export default {
  data() {
    return {};
  },
  created() {
    this.$store.commit("SetActivePage", "contactus");
  },
  components: {
    Contact,
  },
};
</script>
<style lang="scss" scoped>
@media screen and (min-width: 1024px) {
  .contact {
    position: relative;
    width: 100%;
    min-width: 1200px;
    .contact_bg {
      width: 94.3%;
      position: absolute;
      left: 0;
      top: -160px;
      z-index: 1;
    }
    .floor1 {
      position: relative;
      z-index: 2;
      .title1 {
        font-style: normal;
        font-weight: bold;
        font-size: 60px;
        line-height: 80px;
        color: #ffffff;
        padding: 252px 0 0 17%;
        display: inline-block;
        vertical-align: top;
        position: relative;
        z-index: 3;
      }
      .title2 {
        font-style: normal;
        font-weight: bold;
        font-size: 130px;
        height: 172px;
        color: #ffffff;
        opacity: 0.15;
        position: absolute;
        z-index: 2;
        left: 12.7%;
        top: 207px;
      }
      .floor1_img {
        width: 47%;
        margin-left: 15.36%;
        display: inline-block;
        vertical-align: top;
        padding-top: 25px;
      }
    }
  }
  .h5 {
    display: none;
  }
}
@media screen and (max-width: 1024px) {
  .contact {
    display: none;
  }
  .h5 {
    width: 100%;
    img {
      width: 100%;
      display: block;
      margin-top: -86px;
      z-index: 2;
      margin-bottom: 30px;
    }
  }
}
</style>
